import React, { Component } from 'react'
import stickybits from 'stickybits'
//
import Header from './header'
import Footer from './footer'
//
import '../styles/layout.css'
import styles from './layout.module.css'
//
//
class Layout extends Component {
  constructor(props) {
    super(props)
    if (typeof document !== 'undefined') {
      // AOS.init()
      // gsap.registerPlugin(ScrollToPlugin)
    }
    this.state = {
      isLoaded: false,
      isSticky: false
      // showNav: this.props.showNav,
      // navOpen: false
    }
  }

  UNSAFE_componentWillReceiveProps() {
    // AOS.refresh()
  }

  componentDidMount() {
    if (typeof document !== 'undefined' || document !== null) {
      // console.log('this', this)
      const header = document.querySelector('header')
      const navToggle = document.querySelector('header button')
      const logo = document.querySelector('header img')
      const nav = document.querySelector('.nav')

      stickybits(header, { useStickyClasses: true })
    }
  }
  componentWillUnmount() {
    document.querySelector('header button').removeEventListener('mouseover', event)
  }
  render() {
    const { children, siteTitle } = this.props

    return (
      <>
        <Header siteTitle={siteTitle} />
        <div className={styles.content}>{children}</div>
        <Footer />
      </>
    )
  }
}
export default Layout
