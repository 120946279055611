import React from 'react'
//
import styles from './footer.module.css'

const Footer = () => {
  return (
    <footer className={styles.root}>
      <div className={styles.footerWrapper}>
        <div
          className={`flex w-full flex-col superstock:flex-row flex-wrap superstock:flex-no-wrap justify-evenly content-center ${styles.footerLinks}`}
        >
          <div className={`w-full superstock:w-1/3 mb-3 superstock:mb-0`}>
            <h4 className={`text-xl supersport:text-2xl font-bold font-display`}>
              Underwood links
            </h4>
            <ul className={`list-none ml-0`}>
              <li>
                <a href="#">Legalese</a>
              </li>
              <li>
                <a href="#">About us</a>
              </li>
              <li>
                <a href="#">Contact us</a>
              </li>
              <li>
                <a href="#">Privacy policy</a>
              </li>
              <li>
                <a href="#">Terms of work</a>
              </li>
            </ul>
          </div>{' '}
          <div
            className={`w-full superstock:w-1/3  text-left superstock:text-center mb-3 superstock:mb-0`}
          >
            <h4 className={`text-xl supersport:text-2xl font-bold font-display`}>
              Building trade links
            </h4>

            <ul className={`list-none ml-0`}>
              <li>
                <a href="#">HSE</a>
              </li>
              <li>
                <a href="#">Building regulations</a>
              </li>
              <li>
                <a href="#">Another useful link</a>
              </li>
              <li>
                <a href="#">Building trade somesuch</a>
              </li>
              <li>
                <a href="#">Another building link</a>
              </li>
            </ul>
          </div>{' '}
          <div className={`w-full superstock:w-1/3 text-left superstock:text-right mb-0`}>
            <h4 className={`text-xl supersport:text-2xl font-bold font-display`}>Our partners</h4>

            <ul className={`list-none ml-0`}>
              <li>
                <a href="#">Timbertrends Ltd</a>
              </li>
              <li>
                <a href="#">Bob the Builder</a>
              </li>
              <li>
                <a href="#">Foresite Web Services</a>
              </li>
              <li>
                <a href="#">John the Stonemason</a>
              </li>
              <li>
                <a href="#">Knock on Wood Carpentry</a>
              </li>
            </ul>
          </div>
        </div>
        <div className={`${styles.siteInfo} my-3 pt-3 `}>
          <p>© {new Date().getFullYear()}, Thomson Davie Ltd, GL5 1PY</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
