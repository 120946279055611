import React, { useState } from 'react'
import { Link } from 'gatsby'
import Icon from './icon'
import { motion } from 'framer-motion'
//
import { cn } from '../lib/helpers'
//
import Logo from './elements/logo'
import styles from './header.module.css'
//
const menuToggle = {
  hidden: {
    opacity: 1,
    zIndex: 2
  },
  visible: {
    backgroundColor: '#3f004a',
    boxShadow: '0 0 0 rgba(0,0,0,0)',
    opacity: 1,
    rotate: 0,
    mass: 1,
    transition: {
      default: {
        duration: 0.3,
        ease: 'easeIn'
      },
      mass: {
        duration: 0.3,
        ease: 'easeIn'
      }
    }
  },
  hover: {
    backgroundColor: '#e0b920',
    rotate: 90,
    mass: 0.4,
    borderRadius: '50%',
    velocity: 2
  },
  tap: {
    backgroundColor: '#e0b920',
    rotate: 360,
    mass: 1,
    borderRadius: '10%',
    transition: {
      default: {
        duration: 0.5,
        ease: 'easeIn'
      },
      mass: { duration: 0.5, ease: 'easeIn' }
    }
  },
  navOpen: {
    backgroundColor: '#3f004a',
    boxShadow: '0 0 8px rgba(0,0,0,0.8)',
    opacity: 1,
    borderRadius: '50%'
  }
}

const navAnim = {
  hidden: {
    backgroundColor: '#3f004a',
    opacity: 0,
    y: '-100vh',
    mass: 0,
    transition: {
      default: { duration: 0.5, delay: 0, ease: [0.17, 0.37, 0.43, 0.37], type: 'spring' },
      mass: { duration: 0.4, ease: 'easeInOut', damping: 15 }
    }
  },
  visible: {
    backgroundColor: '#3f004a',
    opacity: 1,
    y: 0,
    mass: 200,
    transition: {
      default: {
        duration: 0.5,
        delay: 0,
        ease: [0.17, 0.37, 0.43, 0.37],
        type: 'tween',
        velocity: 50
      },
      opacity: { duration: 0.2, delay: 0.3 },
      y: { duration: 0.3, delay: 0, type: 'spring', damping: 10 }
    }
  }
}
const navUl = {
  hidden: {
    opacity: 0,
    transition: {
      when: 'afterChildren'
    }
  },
  visible: {
    opacity: 1
    // transition: {
    //   transition: {
    //     when: 'beforeChildren',
    //     staggerChildren: 0.3
    //   }
    // }
  }
}
const navItems = {
  hidden: {
    opacity: 1
  },
  visible: {
    opacity: 1
  },
  hover: {
    scale: 1.2
  }
}
const spring = {
  type: 'spring',
  damping: 30,
  stiffness: 300
}

const Header = ({ siteTitle, isSticky }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <header className={cn(styles.root, `header`)} data-sticky={isSticky}>
      <div className={cn(styles.wrapper, `relative`)}>
        <div className={cn(styles.branding, `branding`)}>
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <motion.button
          className={cn(styles.toggleNavButton, `block supersport:hidden`)}
          onClick={() => setIsOpen(!isOpen)}
          aria-label={`Open the menu`}
          initial="hidden"
          animate={isOpen ? 'navOpen' : 'visible'}
          variants={menuToggle}
          whileHover="hover"
          whileTap="tap"
        >
          <Icon symbol="hamburger" />
        </motion.button>

        <div className="hidden absolute right-0 w-auto flex-shrink supersport:flex align-right lg:items-center">
          <ul className="text-md superbike:text-lg list-none font-display font-bold supersport:flex-grow text-right desktop-nav">
            <li className="block mt-4 lg:inline-block lg:mt-0 overflow-hidden">
              <Link
                to="/gallery/"
                className={`transform transition duration-300 text-white hover:text-brand-accent scale-100 hover:scale-110 p-2 superbike:p-4`}
              >
                Gallery
              </Link>
            </li>
            <li className="block mt-4 lg:inline-block lg:mt-0 overflow-hidden">
              <Link
                to="/projects/"
                className={`transform transition duration-300 text-white hover:text-brand-accent scale-100 hover:scale-110 p-2 superbike:p-4`}
              >
                Projects
              </Link>
            </li>
            <li className="block mt-4 lg:inline-block lg:mt-0 overflow-hidden">
              <Link
                to="/about/"
                className={`transform transition duration-300 text-white hover:text-brand-accent scale-100 hover:scale-110 p-2 superbike:p-4`}
              >
                About
              </Link>
            </li>
            <li className="block mt-4 mr-2 lg:inline-block lg:mt-0 overflow-hidden">
              <Link
                to="/contact/"
                className={`transform transition duration-300 text-white hover:text-brand-accent scale-100 hover:scale-110 p-2 superbike:p-4`}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>

        <motion.nav
          className={cn('nav', styles.nav)}
          variants={navAnim}
          initial="hidden"
          animate={isOpen ? 'visible' : 'hidden'}
        >
          <motion.ul
            variants={navUl}
            initial="hidden"
            animate="visible"
            whileHover="hover"
            // className="w-full block flex-grow lg:flex lg:items-center lg:w-auto"
          >
            <motion.li
              variants={navItems}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              // className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 overflow-hidden"
            >
              <Link to="/gallery/">Gallery</Link>
            </motion.li>
            <motion.li
              variants={navItems}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 overflow-hidden"
            >
              <Link to="/projects/">Projects</Link>
            </motion.li>
            <motion.li
              variants={navItems}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 overflow-hidden"
            >
              <Link to="/about/">About</Link>
            </motion.li>
            <motion.li
              variants={navItems}
              initial="hidden"
              animate="visible"
              whileHover="hover"
              className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4 overflow-hidden"
            >
              <Link to="/contact/">Contact</Link>
            </motion.li>
          </motion.ul>
        </motion.nav>
      </div>
    </header>
  )
}

export default Header
